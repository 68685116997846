import React from "react";
import { Route, Routes } from "react-router-dom";

import CreateMenuItem from "../../screens/create-menu-item";
import DetailedOrder from "../../screens/detailed-order";
import Invoices from "../../screens/invoices";
import NotFound from "../../screens/not-found";
import OrderHistory from "../../screens/order-history";
import Orders from "../../screens/orders";
import RestaurantMenu from "../../screens/restaurant-menu";
import Settings from "../../screens/settings";
import BusinessHoursScreen from "../../screens/business-hours";

export const routes = [
  {
    path: "/",
    title: "Aufträge",
    name: "Aufträge",
    element: <Orders />,
  },
  {
    path: "/order/:id",
    title: "Auftrag",
    name: "Auftrag",
    element: <DetailedOrder />,
  },
  {
    path: "menu",
    title: "Speisekarte",
    name: "Speisekarte",
    element: <RestaurantMenu />,
  },
  {
    path: "menu/create",
    title: "Speise erstellen",
    name: "Speise erstellen",
    element: <CreateMenuItem />,
  },
  {
    path: "menu/edit/:id",
    title: "Speise editieren",
    name: "Speise editieren",
    element: <CreateMenuItem />,
  },
  {
    path: "order-history",
    title: "Auftragshistorie",
    name: "Auftragshistorie",
    element: <OrderHistory />,
  },
  {
    path: "invoices",
    title: "Rechnungen",
    name: "Rechnungen",
    element: <Invoices />,
  },
  {
    path: "business-hours",
    title: "Öffnungszeiten",
    name: "Öffnungszeiten",
    element: <BusinessHoursScreen />,
  },
  {
    path: "settings",
    title: "Einstellungen",
    name: "Einstellungen",
    element: <Settings />,
  },
  {
    title: "Catch All - 404",
    name: "404",
    path: "*",
    element: <NotFound />,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes.map(({ path, element }, key) => {
        return <Route path={path} key={key} element={element} />;
      })}
    </Routes>
  );
}
