import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Typography,
} from "@mui/material";

import { useRestaurantContext } from "../../context/restaurant-context";
import { RestaurantAPI } from "../../api-client";

export default function OrdersHeader() {
  const restaurantContext = useRestaurantContext();

  const updateAutoPickUp = async (value: string) => {
    if (!restaurantContext.restaurant?.id) {
      return;
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurantContext.restaurant?.id,
      autoPickUp: value,
    });

    restaurantContext.setRestaurant(updatedRestaurant);
  };

  const buttons = [
    <Button
      key="5"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "5"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("5")}
      color="secondary"
    >
      5 min
    </Button>,
    <Button
      key="10"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "10"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("10")}
      color="secondary"
    >
      10 min
    </Button>,
    <Button
      key="20"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "20"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("20")}
      color="secondary"
    >
      20 min
    </Button>,
    <Button
      key="30"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "30"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("30")}
      color="secondary"
    >
      30 min
    </Button>,
    /*  <Button
      key="0"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "0" ||
        !restaurantContext?.restaurant?.autoPickUp
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("0")}
      color="secondary"
    >
      manuell
    </Button>, */
  ];

  return (
    <Box flexDirection={"row"} display="flex" justifyContent={"space-between"}>
      <Box>
        <Typography variant="h5">Aktuelle Bestellungen</Typography>
        <Typography variant="subtitle2">
          (Es werden ausschließlich Bestellungen angezeigt, die heute
          eingegangen sind.)
        </Typography>
      </Box>

      <Box>
        <Typography>Bestellung automatisch auf abholbereit setzen</Typography>
        <FormControl margin={"dense"} size="medium">
          <Box>
            <ButtonGroup
              orientation="horizontal"
              aria-label="horizontal outlined button group"
            >
              {buttons}
            </ButtonGroup>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
}
