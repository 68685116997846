import { useState } from "react";

import _ from "lodash";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { Loader } from "@aws-amplify/ui-react";
import { LoadingButton } from "@mui/lab";
import { Popconfirm } from "antd";
import { Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import moment from "moment";

import { Order, OrderStatus } from "../../API";
import { useOrdersContext } from "../../context/orders-context";
import OrdersHeader from "./orders-header";
import renderOrderStatus, {
  statusToColor,
} from "../../utils/renderOrderStatus";
import updateOrderStatus from "../../utils/updateOrderStatus";

export default function Orders() {
  const navigate = useNavigate();
  const { orders, loading: fetchingOrders, fetchOrders } = useOrdersContext();
  const [loading, setLoading] = useState("");

  const onClickedOnRow = async (orderItem: Order) => {
    setLoading(orderItem.id);
    // await markOrderAsRead(orderItem.id);
    setTimeout(() => {
      setLoading("");
      navigate(`/order/${orderItem.id}`);
    }, 750);
  };

  return (
    <Box>
      <OrdersHeader />

      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        margin={3}
      >
        <LoadingButton
          fullWidth
          variant="outlined"
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 40,
          }}
          color={"secondary"}
          loading={fetchingOrders}
          startIcon={<Refresh />}
          onClick={() => fetchOrders()}
        >
          {fetchingOrders ? "Bestellungen werden geladen..." : "Aufträge laden"}
        </LoadingButton>
      </Box>

      <List>
        {orders.map((order) => {
          return (
            <ListItem key={order.id} component="li" divider>
              <ListItemButton
                disabled={order.id === loading || fetchingOrders}
                sx={{
                  ":hover": {
                    bgcolor: "primary.main", // theme.palette.primary.main
                    color: "white",
                  },
                }}
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 8,
                  marginBottom: 8,
                }}
                onClick={() => onClickedOnRow(order)}
              >
                <Box
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box>
                    <div
                      style={{
                        height: 24,
                        width: 24,
                        borderRadius: 24,
                        marginRight: 16,
                        backgroundColor:
                          order.id === loading
                            ? "transparent"
                            : _.get(statusToColor, order.status, "black"),
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {order.id === loading && <Loader size="large" />}
                    </div>
                  </Box>
                  <Box>
                    <Typography fontWeight={"bold"} fontSize={18}>
                      {moment(order.createdAt).format("DD.MM - HH:mm") + " Uhr"}
                    </Typography>
                    <Typography fontWeight={"bold"} fontSize={26}>
                      #{order.id.substring(0, 4).toUpperCase()}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography fontWeight={"200"} fontSize={16}>
                    {order.note && order.note.length > 100
                      ? order.note.substring(0, 100) + "…"
                      : order.note}
                  </Typography>
                </Box>

                <Box>{renderOrderStatus(order.status)}</Box>
              </ListItemButton>

              <Popconfirm
                placement={"topLeft"}
                title={"Sind Sie sicher das sie den Auftrag ablehnen möchten?"}
                onConfirm={() =>
                  updateOrderStatus(
                    OrderStatus.DECLINED_BY_RESTAURANT,
                    order,
                    fetchOrders
                  )
                }
                okText={"Ja"}
                cancelText={"Nein"}
              >
                <Button color="error">ABLEHNEN</Button>
              </Popconfirm>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
