const getFees = (invoiceDateRange: string | null) => {
  const startDate = invoiceDateRange?.split(" - ")[0].split(".");
  const year = startDate ? startDate[2] : "2024";
  const month = startDate ? startDate[1] : "01";
  const day = startDate ? startDate[0] : "01";

  const invoiceDate = `${year}-${month}-${day}`;

  const currentDate = new Date(invoiceDate); // use the date of the order creation
  const date05022024 = new Date("2024-02-05");
  const date01042024 = new Date("2024-04-01");

  console.log("getFees", {
    currentDate,
    date05022024,
    date01042024,
  });

  let _restaurantFee = 25; // latest values
  let _deliveryFee = 4.99; // latest values
  let _customerFee = 103; // latest values

  // bring back as soon as the frontend is ready
  // ready in terms of sending metadata to payment intent
  if (currentDate < date05022024) {
    _customerFee = 103;
    _deliveryFee = 4.5;
    _restaurantFee = 20;
  } else if (currentDate >= date05022024 && currentDate < date01042024) {
    _customerFee = 103;
    _deliveryFee = 4.9;
    _restaurantFee = 20;
  }
  /*  else {
    // beginning from 01.04.2024 we will obtain the fees from the payment intent metadata
  } */

  return {
    customerFee: _customerFee,
    deliveryFee: _deliveryFee,
    restaurantFee: _restaurantFee,
  };
};

export default getFees;
