import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUITypography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { useRestaurantContext } from "../../context/restaurant-context";
import { Restaurant } from "../../models";

export default function LoggingSwitch() {
  const theme = useTheme();

  const { restaurant, updateRestaurantLoggingStatus } = useRestaurantContext();
  const [loggingEnabled, setLoggingEnabled] =
    useState<Restaurant["loggingEnabled"]>(false);

  const enabled = !!restaurant?.loggingEnabled;

  useEffect(() => {
    setLoggingEnabled(enabled);
  }, [restaurant?.loggingEnabled]);

  return (
    <Box
      maxWidth={360}
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <MUITypography variant="caption">
        Falls es Probleme in der Benutzung des Restaurant Dashboards gibt,
        kannst du hier das Logging einschalten. Das Entwickler Team kann dann
        potentielle Fehlerquellen nachvollziehen
      </MUITypography>

      <FormControlLabel
        style={{ alignSelf: "center", margin: 16 }}
        checked={Boolean(loggingEnabled)}
        defaultChecked={enabled}
        onChange={(e) => {
          // @ts-ignore
          updateRestaurantLoggingStatus(e.target.checked);
        }}
        control={<Switch size="small" disabled={!restaurant?.id} />}
        label={
          <MUITypography
            variant="caption"
            color={
              enabled ? theme.palette.secondary.main : theme.palette.error.main
            }
          >{`${enabled ? "AKTIV" : "INAKTIV"}`}</MUITypography>
        }
        labelPlacement="bottom"
      />
    </Box>
  );
}
