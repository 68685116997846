import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Button, Card, Descriptions, Divider, List, message } from "antd";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Order, OrderDish, OrderStatus, User } from "../../API";
import { OrderAPI, OrderDishAPI, UserAPI } from "../../api-client";
import { StylesInterface } from "../../types/styles.interface";
import formatPrice from "../../utils/formatPrice";
import LoadingOverlay from "../../components/loading-overlay";
import OrderDishItem from "./order-dish-item";
import renderOrderStatus from "../../utils/renderOrderStatus";

export default function DetailedOrder() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [order, setOrder] = React.useState<Order>();
  const [customer, setCustomer] = React.useState<User>();
  const [orderDishes, setOrderDishes] = React.useState<OrderDish[]>([]);

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Leider konnte der Auftrag nicht geladen werden...",
    });
  };

  const updateOrderStatus = async (newStatus: OrderStatus) => {
    setLoading(true);
    try {
      await OrderAPI.updateOrderByID({ id: order?.id!, status: newStatus });

      messageApi.open({
        type: "success",
        content: "Auftrag aktualisiert",
      });
    } catch (error) {
      console.error("###### ERROR | updateOrderStatus", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  async function fetchData(id: string) {
    try {
      setLoading(true);
      /**
       * Fetch Order by ID
       */
      const theOrder = await OrderAPI.fetchOrderByID({ id });

      if (!theOrder) {
        return error();
      }

      setOrder(theOrder);

      /**
       * Fetching OrderDish
       */
      const theOrderDishes = await OrderDishAPI.queryListByOrderID({
        orderID: theOrder?.id,
      });
      setOrderDishes(theOrderDishes);

      /**
       * Fetching User
       */
      const theUser = await UserAPI.queryItem({ id: theOrder?.userID });
      setCustomer(theUser);

      setTimeout(() => {
        setLoading(false);
      }, 750);
    } catch (e: any) {
      error();
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    // Fetch Data
    fetchData(id);
  }, [id]);

  /**
   * Observe Model Order of type UPDATE & matching to order id
   */
  useEffect(() => {
    if (!id) {
      return;
    }

    const subscription = OrderAPI.observeOrders({
      filter: { id: { eq: id } },
    }).subscribe({
      next: ({ provider, value }) => {
        console.log("[OBSERVE ORDER]", { value });
        if (value?.data?.onUpdateOrder) {
          setOrder(value?.data?.onUpdateOrder as Order);
          messageApi.open({
            type: "warning",
            content: "Der Auftrag wurde aktualisiert",
          });
        }
      },
      error: (error) => console.error(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"} minWidth="100%">
        <Button onClick={() => navigate(-1)} style={{ marginBottom: 8 }}>
          Zurück
        </Button>

        <Button
          type="primary"
          disabled={!order?.id}
          onClick={() => order?.id && fetchData(order?.id)}
          style={{ marginBottom: 8 }}
        >
          Neu laden
        </Button>
      </Box>

      <LoadingOverlay loading={loading} />

      <Card title={t("app.order-detail.title") + " " + id}>
        <Box marginBottom={2}>
          {order?.status && renderOrderStatus(order.status)}
        </Box>
        <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
          <Descriptions.Item label={t("app.order-detail.customer")}>
            {customer?.name}
          </Descriptions.Item>
          <Descriptions.Item label={"Telefon"}>
            {customer?.phone}
          </Descriptions.Item>
          <Descriptions.Item label={"Adresse"}>
            {customer?.address || customer?.addressData?.formattedAddress}
          </Descriptions.Item>
          <Descriptions.Item label={"Total"}>
            <strong>{formatPrice(order?.total!)}</strong>
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <List
          dataSource={orderDishes}
          renderItem={(d) => (
            <OrderDishItem
              key={d.id}
              orderDishItem={d}
              orderStatus={order?.status}
            />
          )}
        />

        <Divider />

        {order?.note && (
          <Box
            m={2}
            p={2}
            style={{ backgroundColor: "orange", borderRadius: 4 }}
          >
            <Typography
              fontWeight={900}
              style={{ textDecorationLine: "underline" }}
            >
              Anmerkung zur Bestellung
            </Typography>
            <Typography>{order?.note}</Typography>
          </Box>
        )}
        <Divider />

        <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}></Descriptions>

        <Divider />

        <>
          {order?.status === OrderStatus.NEW && (
            <div style={styles.buttonsContainer}>
              <Button
                block
                danger
                size={"large"}
                style={styles.button}
                onClick={() =>
                  updateOrderStatus(OrderStatus.DECLINED_BY_RESTAURANT)
                }
              >
                Bestellung ablehnen
              </Button>
              <Button
                block
                type={"primary"}
                size={"large"}
                style={styles.button}
                onClick={() => updateOrderStatus(OrderStatus.COOKING)}
              >
                Bestellung annehmen
              </Button>
            </div>
          )}

          {order?.status === OrderStatus.COOKING && (
            <div style={styles.buttonsContainer}>
              <Button
                block
                type={"primary"}
                size={"large"}
                style={styles.button}
                onClick={() => updateOrderStatus(OrderStatus.READY_FOR_PICKUP)}
              >
                Bereit zur Abholung
              </Button>
            </div>
          )}

          {/*    <div style={styles.buttonsContainer}>
            <Button
              block
              size={"large"}
              style={styles.button}
              onClick={() => updateOrderStatus(OrderStatus.NEW)}
            >
              Reset (DEV)
            </Button>
          </div> */}
        </>
      </Card>
    </Box>
  );
}

const styles: StylesInterface = {
  totalSumContainer: {
    flexDirection: "row",
    display: "flex",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
  },
  button: {
    margin: 8,
  },
};
