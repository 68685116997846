import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUITypography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { useRestaurantContext } from "../../context/restaurant-context";
import { RestaurantStatus } from "../../models";

export default function RestaurantStatusSwitch() {
  const theme = useTheme();

  const { restaurant, updateRestaurantStatus } = useRestaurantContext();
  const [status, setStatus] = useState<boolean>(false);

  const isActive = restaurant?.status === RestaurantStatus.ACTIVE;

  useEffect(() => {
    setStatus(isActive);
  }, [restaurant?.status]);

  function updateStatus(checked: boolean) {
    updateRestaurantStatus(
      checked ? RestaurantStatus.ACTIVE : RestaurantStatus.INACTIVE
    );
  }

  return (
    <Box
      maxWidth={360}
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <MUITypography>
        Hier kannst du das Restaurant in der Kunden App auf INAKTIV oder AKTIV
        setzen
      </MUITypography>

      <FormControlLabel
        style={{ alignSelf: "center", margin: 16 }}
        checked={status}
        defaultChecked={isActive}
        onChange={(e) => {
          // @ts-ignore
          updateStatus(e.target.checked);
        }}
        control={<Switch disabled={!restaurant?.id} />}
        label={
          <MUITypography
            color={
              isActive ? theme.palette.secondary.main : theme.palette.error.main
            }
          >{`${isActive ? "AKTIV" : "INAKTIV"}`}</MUITypography>
        }
        labelPlacement="bottom"
      />
    </Box>
  );
}
